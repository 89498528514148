import React, { useState } from "react";
import {
  Box,
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton,
  Container
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import PeopleIcon from "@mui/icons-material/People";

import { useNavigate } from "react-router-dom";

//theme
import MainAppbar from "./MainAppbar.jsx";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
  })
);

// const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open"
// })(({ theme, open }) => ({
//   backgroundColor: "rgba(128, 206, 190, 1)",
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen
//   }),
//   ...(open && {
//     width: `calc(100% - ${drawerWidth}px)`,
//     marginLeft: `${drawerWidth}px`,
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen
//     })
//   })
// }));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end"
}));

function CustomDrawer({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openList, setOpenList] = useState(true);
  const [state, setState] = useState(false);

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpenList(!openList);
  };

  const handleRoute = (path) => {
    return () => {
      setState(false);
      navigate(path);
    };
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    window.location = "/";
  };

  return (
    <Box sx={{ height: "100vh", width: "100%" }}>
      <MainAppbar {...{ open, handleDrawerOpen }} />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#280631"
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Toolbar sx={{ backgroundColor: "white" }}>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Grid item>
              <img src="https://happypay.asia/logo.svg" height={50} alt="HappypayLogo"/>
              {/* <Typography color="#fff">Happy Pay</Typography> */}
            </Grid>
            <Grid item>
              <IconButton onClick={handleDrawerClose} sx={{ ml: 2 }}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon sx={{ color: "#280631" }} />
                ) : (
                  <ChevronRightIcon sx={{ color: "#280631" }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Divider />
        {/* <Box sx={{ textAlign: "center", p: 2 }}>
          <Typography variant="caption" color="#fff" fontWeight="bold">
            {localStorage.getItem("name")
              ? localStorage.getItem("name")
              : "test"}
          </Typography>
        </Box>
        <Divider /> */}
        <List>
          <ListItemButton onClick={handleRoute("/dashboard")}>
            <ListItemIcon>
              <DashboardIcon sx={{ color: "#888" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="Dashboard" />
          </ListItemButton>

          <ListItemButton onClick={handleRoute("/deposit")}>
            <ListItemIcon>
              <AccountBalanceWalletIcon sx={{ color: "#888" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="Deposits" />
          </ListItemButton>

          <ListItemButton onClick={handleRoute("/withdraw")}>
            <ListItemIcon>
              <AccountBalanceIcon sx={{ color: "#888" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="Withdraws" />
          </ListItemButton>
          <ListItemButton onClick={handleRoute("/customer")}>
            <ListItemIcon>
              <PeopleIcon sx={{ color: "#888" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="Customer" />
          </ListItemButton>
          {/* <ListItemButton onClick={handleRoute("/topups")}>
            <ListItemIcon>
              <PlaylistAddCircleIcon sx={{ color: "#888" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="Topups" />
          </ListItemButton>
          <ListItemButton onClick={handleRoute("/settle")}>
            <ListItemIcon>
              <PlaylistAddCircleIcon sx={{ color: "#888" }} />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="Settle" />
          </ListItemButton> */}
        </List>
        <Divider sx={{ backgroundColor: "#666" }} />
        <List>
          <ListItemButton onClick={handleRoute("/credential")}>
            {/* <ListItemIcon>
              <SecurityIcon sx={{ color: "#888" }} />
            </ListItemIcon> */}
            <ListItemText sx={{ color: "white" }} primary="API Credential" />
          </ListItemButton>

          {/* <Divider />
          <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <AccountBalanceIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="Withdraws" />
            {openList ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openList} timeout="auto" unmountOnExit>
            <List component="div" disablePadding sx={{  }}>
              <ListItemButton
                sx={{ pl: 9 }}
                onClick={handleRoute("/withdraws/customer")}
              >
                <ListItemText
                  sx={{
                    color: "white"
                  }}
                  primary="Customer"
                />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 9 }}
                onClick={handleRoute("/withdraws/hq")}
              >
                <ListItemText
                  sx={{
                    color: "white"
                  }}
                  primary="Withdraw Merchant"
                />
              </ListItemButton>
            </List>
          </Collapse>
          <Divider /> */}
          {/* <ListItemButton onClick={handleRoute("/kyc")}>
            <ListItemIcon>
              <VerifiedUserIcon />
            </ListItemIcon>
            <ListItemText sx={{ color: "white" }} primary="KYC" />
          </ListItemButton> */}

          {/* <ListItem disablePadding onClick={handleRoute("/Customers")}>
            <ListItemButton>
              <ListItemIcon>
                <PeopleAltIcon sx={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText sx={{ color: "white" }} primary="Customers" />
            </ListItemButton>
          </ListItem> */}
          <ListItemButton onClick={handleRoute("/settings")}>
            {/* <ListItemIcon>
              <SettingsIcon sx={{ color: "#888" }} />
            </ListItemIcon> */}
            <ListItemText sx={{ color: "white" }} primary="Settings" />
          </ListItemButton>
          <ListItemButton onClick={handleRoute("/change-password")}>
            {/* <ListItemIcon>
              <PasswordIcon sx={{ color: "#888" }} />
            </ListItemIcon> */}
            <ListItemText sx={{ color: "white" }} primary="Change Password" />
          </ListItemButton>
        </List>
        <List sx={{ mt: "auto" }}>
          <ListItemButton onClick={handleLogout}>
            <ListItemText sx={{ color: "white" }} primary="Logout" />
            <ExitToAppIcon sx={{ color: "#888" }} />
          </ListItemButton>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Container maxWidth={false} disableGutters sx={{ pb: 3 }}>
          {children}
        </Container>
      </Main>
    </Box>
  );
}

export default CustomDrawer;