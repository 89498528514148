import React, { useState } from "react";
import {
  Paper,
  Stack,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  TextField,
  Button
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";

import { useRecoilState } from "recoil";
import {
  withdrawFilterStatusState,
  withdrawFilterStartState,
  withdrawFilterEndState
} from "../../states/withdraw-list";
import DialogCusWithdraw from "./DialogCusWithdraw.jsx";
import CustomerContext from "../../contexts/CustomerContext.jsx";
import dayjs from "dayjs";

function SearchWithdrawals(props) {
  const [withdrawFilterStatus, setwithdrawFilterStatus] = useRecoilState(
    withdrawFilterStatusState
  );
  const [withdrawFilterStart, setwithdrawFilterStart] = useRecoilState(
    withdrawFilterStartState
  );
  const [withdrawFilterEnd, setwithdrawFilterEnd] = useRecoilState(
    withdrawFilterEndState
  );

  const handleChangeStartDate = (startValue) => {
    if (startValue && startValue.isAfter(withdrawFilterEnd)) {
      setwithdrawFilterEnd(startValue);
    }
    setwithdrawFilterStart(startValue);
  };

  const handleChangeEndDate = (endValue) => {
    if (endValue && endValue.isBefore(withdrawFilterStart)) {
      setwithdrawFilterStart(endValue);
    }
    setwithdrawFilterEnd(endValue);
  };

  const handleChange = (event) => {
    setwithdrawFilterStatus(event.target.value);
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <React.Fragment>
        <CustomerContext.Provider
          value={{
            openDialog,
            setOpenDialog
          }}
        >
          <Paper sx={{ p: 3 }} elevation={0}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container spacing={2} sm={12} xs={12}>
                <Grid container item sm={10} xs={12} md={10}>
                  <Grid item sm={4} xs={12} md={10}>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 2, sm: 2, md: 3 }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-select-small">Status</InputLabel>
                        <Select
                          labelId="demo-select-small"
                          id="demo-select-small"
                          value={withdrawFilterStatus}
                          label="Status"
                          onChange={handleChange}
                        >
                          <MenuItem value={0}>All</MenuItem>
                          <MenuItem value={1}>Success</MenuItem>
                          <MenuItem value={2}>Pending</MenuItem>
                          <MenuItem value={3}>Failed</MenuItem>
                        </Select>
                      </FormControl>

                      <DesktopDatePicker
                        label="Start"
                        inputFormat="DD/MM/YYYY"
                        value={withdrawFilterStart}
                        onChange={handleChangeStartDate}
                        maxDate={dayjs()}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                      <DesktopDatePicker
                        label="End"
                        inputFormat="DD/MM/YYYY"
                        value={withdrawFilterEnd}
                        onChange={handleChangeEndDate}
                        minDate={withdrawFilterStart} // Ensures end date is not before start date
                        maxDate={dayjs()}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <Grid item mt={1}>
                  <Box>
                    <Button
                      onClick={props.exportCsv}
                      variant="contained"
                      fullWidth
                      sx={{ height: "100%" }}
                      startIcon={<ImportExportIcon />}
                    >
                      Export
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Paper>
          <DialogCusWithdraw />
        </CustomerContext.Provider>
      </React.Fragment>
    </>
  );
}

export default SearchWithdrawals;