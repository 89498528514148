import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow
} from "@mui/material";

import { useRecoilState, useRecoilValue } from "recoil";
import dayjs from "dayjs";

//formats
import { decimalFourFormat } from "../../utils/formats.js";
import { depositListState } from "../../states/deposit-list.js";

import ChipSuccess from "../commons/ChipSuccess.jsx";
import ChipFail from "../commons/ChipFail.jsx";
import ChipPending from "../commons/ChipPending.jsx";

import EnhancedTableHead from "../commons/EnhancedTableHead.jsx";
import _ from "lodash";
import DepositDialog from "./DepositDialog.jsx";
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

function HistoryDepositTable(props) {
  const [headCells] = useState([
    {
      id: "time",
      label: "Time",
      numeric: false,
      disablePadding: true,
      minWidth: 200
    },
    {
      id: "m_refno",
      label: "Merchant TX Id",
      numeric: false,
      disablePadding: false,
      minWidth: 0
    },
    {
      id: "money",
      label: "Amount",
      // minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "awf",
      label: "Amount (Fee)",
      numeric: true,
      disablePadding: false
    },
    {
      id: "fee",
      label: "Fee (%)",
      minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "fee_amount",
      label: "Fee",
      minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "vat",
      label: "Vat 7%",
      minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "p_balance",
      label: "Prev Balance",
      minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "n_balance",
      label: "New Balance",
      minWidth: 50,
      numeric: true,
      disablePadding: false
    },
    {
      id: "status",
      label: "Status",
      minWidth: 50,
      numeric: false,
      disablePadding: false
    }
  ]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDepositDialog, setOpenDepositDialog] = useRecoilState(
    depositListState.openDepositDialog
  );
  const [currentPageHistoryList, setCurrentPageHistoryList] = useRecoilState(
    depositListState.currentPageHistoryList
  );

  const historyDepositList = useRecoilValue(
    depositListState.historyDepositList
  );

  const handleClickOpen = () => {
    setOpenDepositDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPageHistoryList(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPageHistoryList(0);
  };
  // console.log('currentPageHistoryList*rowsPerPage', currentPageHistoryList * rowsPerPage)
  return (
    <>
      <TableContainer>
        <Table
          // aria-labelledby="tableTitle"
          stickyHeader
          aria-label="sticky table"
        // size='medium'
        >
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={historyDepositList && historyDepositList.length}
          />
          <TableBody>
            {_.size(historyDepositList) > 0 &&
              historyDepositList.slice(currentPageHistoryList * rowsPerPage, currentPageHistoryList * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="right">
                      {dayjs.utc(row.dateCreated).format("DD MMM YYYY HH:mm:ss")}
                    </TableCell>
                    <TableCell align="right">
                      {row.clientTransactionId}
                    </TableCell>
                    <TableCell align="right">
                      {decimalFourFormat(row.amount)}
                    </TableCell>
                    <TableCell align="right">
                      {decimalFourFormat(row.amountWithFee)}
                    </TableCell>
                    <TableCell align="right">
                      {decimalFourFormat(row.totalFee)}
                    </TableCell>
                    <TableCell align="right">
                      {decimalFourFormat(row.feeInAmount)}
                    </TableCell>
                    <TableCell align="right">
                      {decimalFourFormat(row.feeInAmount * 0.07)}
                    </TableCell>
                    <TableCell align="right">
                      {decimalFourFormat(row.balanceBefore)}
                    </TableCell>
                    <TableCell align="right">
                      {decimalFourFormat(row.balanceAfter)}
                    </TableCell>
                    {row.status == "successful" ? (
                      <TableCell align="center">
                        <ChipSuccess />
                      </TableCell>
                    ) : row.status == "pending" ? (
                      <TableCell align="center">
                        <ChipPending />
                      </TableCell>
                    ) : (
                      <TableCell align="center">
                        <ChipFail />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={historyDepositList ? historyDepositList.length : 0}
        rowsPerPage={rowsPerPage}
        page={currentPageHistoryList}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <DepositDialog />
    </>
  );
}

export default HistoryDepositTable;
