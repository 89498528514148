import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import React from "react";

import { useEffect } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useAPI } from "../services/happypay-api";
import { ExportToCsv } from "export-to-csv";
import { depositListState } from "../states/deposit-list";
import dayjs from "dayjs";

import ImportExportIcon from "@mui/icons-material/ImportExport";
import PendingDepositTable from "../components/deposit/PendingDepositTable";
import HistoryDepositTable from "../components/deposit/HistoryDepositTable";
import SearchInPendingDepositForm from "../components/deposit/SearchInPendingDepositForm";
import SearchDepositForm from "../components/deposit/SearchDepositForm";
import DepositStat from "../components/deposit/DepositStat";
import { authState } from "../states/auth";
import moment from "moment";
import DialogDeposits from "../components/deposit/QrcodeDialog";
import { decimalFourFormat } from "../utils/formats.js";
let interval;

function DepositPage() {
  const api = useAPI();
  const profile = useRecoilValue(authState.profile);

  const depositFilterStatus = useRecoilValue(depositListState.depositFilterStatus);
  const depositFilterStart = useRecoilValue(depositListState.depositFilterStart);
  const depositFilterEnd = useRecoilValue(depositListState.depositFilterEnd);

  const [, setPendingDepositList] = useRecoilState(depositListState.pendingDepositList);
  const [historyDepositList, setHistoryDepositList] = useRecoilState(depositListState.historyDepositList);

  const inputSearchHistoryDeposit = useRecoilValue(depositListState.inputSearchHistoryDeposit);
  const inputSearchPendingDeposit = useRecoilValue(depositListState.inputSearchPendingDeposit);
  const inputAmountDeposit = useRecoilValue(depositListState.inputAmountDeposit);

  // pagination ad
  const currentPageHistoryList = useRecoilValue(depositListState.currentPageHistoryList);
  const currentPagePendingList = useRecoilValue(depositListState.currentPagePendingList);

  // total balance
  const [, setDepositBalance] = useRecoilState(depositListState.depositBalance);
  const [, setTotalDeposit] = useRecoilState(depositListState.totalDeposit);
  const [, setTodayDeposit] = useRecoilState(depositListState.todayDeposit);
  const [, setTotalPendingDeposit] = useRecoilState(
    depositListState.totalPendingDeposit
  );

  const [qrBase64, setQrCodeDeposit] = useRecoilState(depositListState.qrCodeDeposit)
  const [, setRefNoDeposit] = useRecoilState(depositListState.refNoDeposit)
  const [clientRefno, setMerchantRefNo] = useRecoilState(depositListState.merchantRefNo)
  const [amountGenQr, setAmountGenqr] = useRecoilState(depositListState.amountGenQr)
  const [statusQr, setStatusQr] = useRecoilState(depositListState.statusQr)

  const [openQrcodeDialog, setOpenQrcodeDialog] = useRecoilState(
    depositListState.openQrcodeDialog
  );

  const handleClickOpen = () => {
    setOpenQrcodeDialog(!openQrcodeDialog);
    if (qrBase64 !== '') {
      clearInterval(interval)
    }
  };

  useEffect(() => {

    if (qrBase64 !== '') {
      interval = setInterval(() => {

        api.depositStatus(clientRefno)
          .then((data) => {
            if (data.qrStatus === "successful") {
              //todo stop
              setStatusQr(true)
              clearInterval(interval)
            }
          })
          .catch(console.error);
      }, 5000);
    }


    return () => clearInterval(interval);
  }, [qrBase64]);


  useEffect(() => {
    api
      .balanceMerchant(localStorage.getItem("token"))
      .then((data) => {
        setTotalDeposit(data?.totalDeposit);
        setTodayDeposit(data?.totalDepositToday);
        setTotalPendingDeposit(data?.totalDepositPending);
        setDepositBalance(data.balance);
      })
      .catch((error) => { });
  }, [api]);

  // useEffect(() => {
  //   api
  //     .pendingDepositList(
  //       inputSearchPendingDeposit,
  //       depositFilterStart && dayjs(depositFilterStart).format("YYYY-MM-DD"),
  //       depositFilterEnd && dayjs(depositFilterEnd).format("YYYY-MM-DD"),
  //       currentPagePendingList + 1
  //     )
  //     .then((data) => {
  //       setPendingDepositList(data);
  //     })
  //     .catch((error) => {
  //       const { message, statusCode } = error;
  //     });
  // }, [
  //   profile,
  //   inputSearchPendingDeposit,
  //   depositFilterStatus,
  //   depositFilterStart,
  //   depositFilterEnd,
  //   currentPagePendingList
  // ]);

  useEffect(() => {
    const statusMap = depositFilterStatus === 5 ? "successful,pending,failed" :
      depositFilterStatus === 1 ? "successful" :
        depositFilterStatus === 2 ? "pending" :
          "failed";

    api.historyDepositList(
      inputSearchHistoryDeposit,
      statusMap,
      depositFilterStart && dayjs(depositFilterStart).format("YYYY-MM-DD HH:mm:ss"),
      depositFilterEnd && dayjs(depositFilterEnd).format("YYYY-MM-DD HH:mm:ss"),
      currentPageHistoryList + 1
    )
      .then((data) => {
        setHistoryDepositList(data);
      })
      .catch(console.error);
  }, [
    profile,
    inputSearchHistoryDeposit,
    depositFilterStatus,
    depositFilterStart,
    depositFilterEnd,
    currentPagePendingList
  ]);

  // const exportCsv = () => {
  //   api
  //     .getExportDepositURL(depositFilterStart, depositFilterEnd)
  //     .then((data) => {
  //       window.open(data.url, "_blank");
  //     })
  //     .catch(console.error);
  // };

  const genQr = () => {
    api.genDepositQRCode(inputAmountDeposit)
      .then((data) => {
        setQrCodeDeposit(data.qrBase64)
        setRefNoDeposit(data.refno)
        setMerchantRefNo(data.merchantTxId)
        setAmountGenqr(inputAmountDeposit)
      })
      .catch(console.error);
  }

  const exportCsv_old = () => {
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: true,
      title: `Report Deposit ${localStorage.getItem("name")} ${dayjs(depositFilterStart).format(
        "DD-MM-YYYY"
      )}-${dayjs(depositFilterEnd).format("DD-MM-YYYY")}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: `Report Deposit ${localStorage.getItem("name")} ${dayjs(depositFilterStart).format(
        "DD-MM-YYYY"
      )}-${dayjs(depositFilterEnd).format("DD-MM-YYYY")}`
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);

    let obj = historyDepositList.map((e) => {
      return {
        "Time": moment(e.dateCreated).format("DD/MM/YYYY HH:mm:ss"),
        // customer_name: e.customerName,
        "Merchant TX Id": e.clientTransactionId,
        "Amount": decimalFourFormat(e.amount),
        "Amount (Fee)": decimalFourFormat(e.amountWithFee.toFixed(4)),
        "Fee (%)": e.totalFee,
        "Fee": decimalFourFormat(e.feeInAmount),
        "Vat 7%": (e.feeInAmount * 0.07).toFixed(4),
        // beforeBalance: decimalFourFormat(e.balanceBefore.toFixed(4)),
        // afterBalance: decimalFourFormat(e.balanceAfter.toFixed(4)),
        "Status": e.status
      };
    });

    csvExporter.generateCsv(obj);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="h4" component="h2">
              Deposits
            </Typography>
          </Grid>
          <Grid display={'flex'}>
            {/* <Grid item marginRight={'10px'}>
              <Box>
                <Button
                  onClick={exportCsv}
                  variant="contained"
                  fullWidth
                  sx={{ height: "100%" }}
                  startIcon={<ImportExportIcon />}
                >
                  Export
                </Button>
              </Box>
            </Grid> */}
            <Grid item>
              <Box>
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  fullWidth
                  sx={{ height: "100%" }}
                  startIcon={<ImportExportIcon />}
                >
                  Generate QRCODE
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <DepositStat />
      
      {/* ปิดDeposit Pending */}
      {/* <Box>
        <Paper>
          <Box sx={{ p: 3 }}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Typography fontSize={"1.3rem"}>Deposit Pending</Typography>
              </Grid>
              <Grid item>
                <SearchInPendingDepositForm />
              </Grid>
            </Grid>
          </Box>
          <PendingDepositTable />
        </Paper>
      </Box> */}
      <Box>
        <Paper>
          <Box sx={{ p: 3 }}>
            <Typography fontSize={"1.3rem"}>Deposit History</Typography>
          </Box>
          <Box sx={{ mb: 2 }}>
            <SearchDepositForm exportCsv={exportCsv_old} />
          </Box>
          <HistoryDepositTable data={historyDepositList} />
        </Paper>
      </Box>
      <DialogDeposits genQr={genQr} handleClickOpen={handleClickOpen} />
    </Stack>
  );
}

export default DepositPage;