import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import DialogSubmitTransferSlip from "./DialogSubmitTransferSlip";
import { dialogSubmitTransferSlipState } from "../../states/dialog/submit-transfer-slip";
import { useRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { useAPI } from "../../services/happypay-api";
import moment from "moment";
import ChipPending from "../commons/ChipPending";
import TableHeadCell from "../commons/TableHeadCell";
import TableBodyCell from "../commons/TableBodyCell";

export default function Payslip() {
  const api = useAPI();
  const [openDialog, setOpenDialog] = useRecoilState(
    dialogSubmitTransferSlipState.open
  );

  const [slipPendingList, setSlipPendingList] = useState(null);

  const handleOpenUploadTopup = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    api
      .getSlipList("pending")
      .then((data) => {
        setSlipPendingList(data);
      })
      .catch();
  }, []);

  return (
    <>
      <Paper>
        <Box sx={{ p: 3 }}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Typography fontSize={"1.5rem"}>Topup Slip</Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={handleOpenUploadTopup}>
                Upload topup slip
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Submit Date</TableHeadCell>
                <TableHeadCell>Amount</TableHeadCell>
                <TableHeadCell>Currency</TableHeadCell>
                <TableHeadCell>Date Time</TableHeadCell>
                <TableHeadCell>Refer link</TableHeadCell>
                <TableHeadCell>Slip</TableHeadCell>
                <TableHeadCell>Status</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slipPendingList &&
                slipPendingList.map((row) => {
                  return (
                    <TableRow>
                      <TableBodyCell>
                        {moment(row.dateCreated).format("DD/MMM/YYYY HH:mm")}
                      </TableBodyCell>
                      <TableBodyCell>
                        {row.amount.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })}
                      </TableBodyCell>
                      <TableBodyCell>{row.currency}</TableBodyCell>
                      <TableBodyCell>
                        {moment(row.dateTransfer).format("DD/MMM/YYYY HH:mm")}
                      </TableBodyCell>
                      <TableBodyCell>{row.linkRefer}</TableBodyCell>
                      <TableBodyCell>{row.slipRefer}</TableBodyCell>
                      <TableBodyCell>
                        <ChipPending />
                      </TableBodyCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </Box>
        <DialogSubmitTransferSlip />
      </Paper>
    </>
  );
}
