import {
    Card,
    CardContent,
    Container,
    Grid,
    Paper,
    Typography
} from "@mui/material";
import { useRecoilValue } from "recoil";
import { withdrawState } from "../../states/withdraw";
import CardAmount from "../cards/Amount";

export default function WithdrawBalanceStat() {
    const totalBalance = useRecoilValue(withdrawState.totalBalance);
    const pendingWithdraw = useRecoilValue(withdrawState.pendingWithdraw);
    const withdrawAvailable = useRecoilValue(withdrawState.withdrawAvailable);
    const pendingTopup = useRecoilValue(withdrawState.pendingTopup);

    return (
        <Grid container spacing={2}>
            <Grid item sm={3}>
                <CardAmount
                    label={"Pending Settle"}
                    amount={totalBalance && totalBalance.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
                    }
                />
            </Grid>
        </Grid>
    );
}
