import { Box, Grid } from "@mui/material";
import CardAmount from "../cards/Amount";
import { useRecoilValue } from "recoil";
import { depositListState } from "../../states/deposit-list";

export default function DepositStat() {
  const depositBalance = useRecoilValue(depositListState.depositBalance);
  const totalDeposit = useRecoilValue(depositListState.totalDeposit);
  const todayDeposit = useRecoilValue(depositListState.todayDeposit);
  const totalPendingDeposit = useRecoilValue(
    depositListState.totalPendingDeposit
  );

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Deposit Balance"}
            amount={depositBalance && depositBalance.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Total Deposit"}
            amount={totalDeposit && totalDeposit.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Today Deposit"}
            amount={todayDeposit && todayDeposit.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
            }
          />
        </Grid>
        <Grid item xs={12} md={4} sm={6}>
          <CardAmount
            label={"Pending Deposit"}
            amount={totalPendingDeposit && totalPendingDeposit.toLocaleString('en-US', { minimumFractionDigits: 4, maximumFractionDigits: 4 })
            }
          />
        </Grid>
      </Grid>
    </Box >
  );
}
