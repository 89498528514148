import React from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";

export default function CardAmount({ label, amount }) {
  return (
    <Card>
      <CardContent sx={{ mx: 2 }}>
        <Typography sx={{ mb: 1, fontSize: "1.2rem" }} color="text.secondary">
          {label}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            whiteSpace: "nowrap"
          }}
        >
          &#3647; {amount}
        </Typography>
      </CardContent>
    </Card>
  );
}